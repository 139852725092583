import { useTranslation } from 'next-i18next';

import { Button } from '@lib/theme/components/Button';

export const BackToHomePageButton = () => {
  const { t } = useTranslation('errors');

  const handleBackToHomePage = () => {
    window.location.href = '/';
  };

  return (
    <Button onClick={handleBackToHomePage} color="primary/primary">
      {t('Back to homepage')}
    </Button>
  );
};
