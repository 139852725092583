import Link from 'next/link';

import { cn } from '@/core/ui/utils';
import { FCC } from '@/types/common';

import Logo from '../images/logo.svg';

export const SimpleNavBar: FCC = ({ children }) => (
  <div className={cn('text-white', 'px-2', 'box-border', 'bg-primary-500')}>
    <div
      className={cn(
        'max-w-7xl',
        'mx-auto',
        'grid',
        'grid-cols-3',
        'items-center'
      )}
    >
      {children && <div>{children}</div>}
      <Link href="/">
        <Logo
          className={cn('mx-auto', 'my-3', 'h-12')}
          // It's not clickable so we can hide it from screen readers
          aria-hidden
        />
      </Link>
    </div>
  </div>
);
