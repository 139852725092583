import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { cn } from '@/core/ui/utils';

const currentYear = new Date().getFullYear();

export const SimpleFooter = ({ className }: { className?: string }) => {
  const { t } = useTranslation('layout');

  const footerLinks = [
    { href: '/contact-us', text: t('Contact us') },
    { href: '/about-us', text: t('About us') },
    { href: '/faq', text: t('FAQ') },
    { href: '/terms-of-service', text: t('Terms of service') },
    {
      href: '/polityka-prywatnosci.pdf',
      text: t('Privacy Policy'),
      locale: false,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  ];

  return (
    <footer
      className={cn(
        'border-t',
        'border-gray-200',
        'max-w-7xl',
        'mx-auto',
        'w-full',
        'flex',
        'p-6',
        'text-sm',
        'text-primary-900',
        'font-medium',
        'flex-col',
        'gap-3',
        'md:flex-row',
        'md:items-center',
        'md:justify-between',
        'md:mb-4',
        'lg:mt-8',
        className
      )}
    >
      <div className="text-xs font-normal text-text-subtle">
        <p>
          {t('© {{currentYear}} Delio. All rights reserved.', {
            currentYear,
            ns: 'layout',
          })}
        </p>
      </div>
      <div className="flex gap-2">
        {footerLinks.map(({ text, href, locale, target, rel }) => (
          <Link
            key={href}
            href={href}
            locale={locale === false ? false : undefined}
            target={target}
            rel={rel}
            className={cn(
              'underline',
              'text-primary-900',
              'text-xs',
              'font-normal',
              'md:text-sm',
              'md:no-underline'
            )}
          >
            {text}
          </Link>
        ))}
      </div>
    </footer>
  );
};
